import { InjectionToken } from '@angular/core';
var ɵ0 = function () { return import("./announcement-bar/announcement-bar.module.ngfactory").then(function (mod) { return mod.AnnouncementBarModuleNgFactory; }); }, ɵ1 = function () { return import("./api/api-list.module.ngfactory").then(function (mod) { return mod.ApiListModuleNgFactory; }); }, ɵ2 = function () { return import("./contributor/contributor-list.module.ngfactory").then(function (mod) { return mod.ContributorListModuleNgFactory; }); }, ɵ3 = function () { return import("./search/file-not-found-search.module.ngfactory").then(function (mod) { return mod.FileNotFoundSearchModuleNgFactory; }); }, ɵ4 = function () { return import("./resource/resource-list.module.ngfactory").then(function (mod) { return mod.ResourceListModuleNgFactory; }); }, ɵ5 = function () { return import("./toc/toc.module.ngfactory").then(function (mod) { return mod.TocModuleNgFactory; }); }, ɵ6 = function () { return import("./code/code-example.module.ngfactory").then(function (mod) { return mod.CodeExampleModuleNgFactory; }); }, ɵ7 = function () { return import("./code/code-tabs.module.ngfactory").then(function (mod) { return mod.CodeTabsModuleNgFactory; }); }, ɵ8 = function () { return import("./live-example/live-example.module.ngfactory").then(function (mod) { return mod.LiveExampleModuleNgFactory; }); };
// Modules containing custom elements must be set up as lazy-loaded routes (loadChildren)
// TODO(andrewjs): This is a hack, Angular should have first-class support for preparing a module
// that contains custom elements.
export var ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES = [
    {
        selector: 'aio-announcement-bar',
        loadChildren: ɵ0
    },
    {
        selector: 'aio-api-list',
        loadChildren: ɵ1
    },
    {
        selector: 'aio-contributor-list',
        loadChildren: ɵ2
    },
    {
        selector: 'aio-file-not-found-search',
        loadChildren: ɵ3
    },
    {
        selector: 'aio-resource-list',
        loadChildren: ɵ4
    },
    {
        selector: 'aio-toc',
        loadChildren: ɵ5
    },
    {
        selector: 'code-example',
        loadChildren: ɵ6
    },
    {
        selector: 'code-tabs',
        loadChildren: ɵ7
    },
    {
        selector: 'live-example',
        loadChildren: ɵ8
    }
];
/** Injection token to provide the element path modules. */
export var ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN = new InjectionToken('aio/elements-map');
/** Map of possible custom element selectors to their lazy-loadable module paths. */
export var ELEMENT_MODULE_LOAD_CALLBACKS = new Map();
ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES.forEach(function (route) {
    ELEMENT_MODULE_LOAD_CALLBACKS.set(route.selector, route.loadChildren);
});
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
