import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from 'app/navigation/navigation.service';
import { DocumentService } from 'app/documents/document.service';
import { Deployment } from 'app/shared/deployment.service';
import { LocationService } from 'app/shared/location.service';
import { NotificationComponent } from 'app/layout/notification/notification.component';
import { ScrollService } from 'app/shared/scroll.service';
import { SearchBoxComponent } from 'app/search/search-box/search-box.component';
import { SearchService } from 'app/search/search.service';
import { TocService } from 'app/shared/toc.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
var sideNavView = 'SideNav';
var AppComponent = /** @class */ (function () {
    function AppComponent(deployment, documentService, hostElement, locationService, navigationService, scrollService, searchService, tocService) {
        this.deployment = deployment;
        this.documentService = documentService;
        this.hostElement = hostElement;
        this.locationService = locationService;
        this.navigationService = navigationService;
        this.scrollService = scrollService;
        this.searchService = searchService;
        this.tocService = tocService;
        this.currentNodes = {};
        this.dtOn = false;
        /**
         * These CSS classes are computed from the current state of the application
         * (e.g. what document is being viewed) to allow for fine grain control over
         * the styling of individual pages.
         * You will get three classes:
         *
         * * `page-...`: computed from the current document id (e.g. events, guide-security, tutorial-toh-pt2)
         * * `folder-...`: computed from the top level folder for an id (e.g. guide, tutorial, etc)
         * * `view-...`: computef from the navigation view (e.g. SideNav, TopBar, etc)
         */
        this.hostClasses = '';
        // Disable all Angular animations for the initial render.
        this.isStarting = true;
        this.isTransitioning = true;
        this.isFetching = false;
        this.isSideBySide = false;
        this.isSideNavDoc = false;
        this.sideBySideWidth = 992;
        this.hasFloatingToc = false;
        this.showFloatingToc = new BehaviorSubject(false);
        this.showFloatingTocWidth = 800;
        this.tocMaxHeightOffset = 0;
        // Search related properties
        this.showSearchResults = false;
        this.notificationAnimating = false;
    }
    Object.defineProperty(AppComponent.prototype, "isOpened", {
        get: function () { return this.isSideBySide && this.isSideNavDoc; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "mode", {
        get: function () { return this.isSideBySide ? 'side' : 'over'; },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Do not initialize the search on browsers that lack web worker support
        if ('Worker' in window) {
            // Delay initialization by up to 2 seconds
            this.searchService.initWorker(2000);
        }
        this.onResize(window.innerWidth);
        /* No need to unsubscribe because this root component never dies */
        this.documentService.currentDocument.subscribe(function (doc) { return _this.currentDocument = doc; });
        this.locationService.currentPath.subscribe(function (path) {
            if (path === _this.currentPath) {
                // scroll only if on same page (most likely a change to the hash)
                _this.scrollService.scroll();
            }
            else {
                // don't scroll; leave that to `onDocRendered`
                _this.currentPath = path;
                // Start progress bar if doc not rendered within brief time
                clearTimeout(_this.isFetchingTimeout);
                _this.isFetchingTimeout = setTimeout(function () { return _this.isFetching = true; }, 200);
            }
        });
        this.navigationService.currentNodes.subscribe(function (currentNodes) {
            _this.currentNodes = currentNodes;
            // Redirect to docs if we are in archive mode and are not hitting a docs page
            // (i.e. we have arrived at a marketing page)
            if (_this.deployment.mode === 'archive' && !currentNodes[sideNavView]) {
                _this.locationService.replace('docs');
            }
        });
        // Compute the version picker list from the current version and the versions in the navigation map
        combineLatest(this.navigationService.versionInfo, this.navigationService.navigationViews.pipe(map(function (views) { return views['docVersions']; })))
            .subscribe(function (_a) {
            var versionInfo = _a[0], versions = _a[1];
            // TODO(pbd): consider whether we can lookup the stable and next versions from the internet
            var computedVersions = [
                { title: 'next', url: 'https://next.angular.io/' },
                { title: 'rc', url: 'https://rc.angular.io/' },
                { title: 'stable', url: 'https://angular.io/' },
            ];
            if (_this.deployment.mode === 'archive') {
                computedVersions.push({ title: "v" + versionInfo.major });
            }
            _this.docVersions = computedVersions.concat(versions);
            // Find the current version - eithers title matches the current deployment mode
            // or its title matches the major version of the current version info
            _this.currentDocVersion = _this.docVersions.find(function (version) {
                return version.title === _this.deployment.mode || version.title === "v" + versionInfo.major;
            });
            _this.currentDocVersion.title += " (v" + versionInfo.raw + ")";
        });
        this.navigationService.navigationViews.subscribe(function (views) {
            _this.footerNodes = views['Footer'] || [];
            _this.sideNavNodes = views['SideNav'] || [];
            _this.topMenuNodes = views['TopBar'] || [];
            _this.topMenuNarrowNodes = views['TopBarNarrow'] || _this.topMenuNodes;
        });
        this.navigationService.versionInfo.subscribe(function (vi) { return _this.versionInfo = vi; });
        var hasNonEmptyToc = this.tocService.tocList.pipe(map(function (tocList) { return tocList.length > 0; }));
        combineLatest(hasNonEmptyToc, this.showFloatingToc)
            .subscribe(function (_a) {
            var hasToc = _a[0], showFloatingToc = _a[1];
            return _this.hasFloatingToc = hasToc && showFloatingToc;
        });
        // Generally, we want to delay updating the shell (e.g. host classes, sidenav state) for the new
        // document, until after the leaving document has been removed (to avoid having the styles for
        // the new document applied prematurely).
        // For the first document, though, (when we know there is no previous document), we want to
        // ensure the styles are applied as soon as possible to avoid flicker.
        combineLatest(this.documentService.currentDocument, // ...needed to determine host classes
        this.navigationService.currentNodes) // ...needed to determine `sidenav` state
            .pipe(first())
            .subscribe(function () { return _this.updateShell(); });
    };
    AppComponent.prototype.onDocReady = function () {
        var _this = this;
        // About to transition to new view.
        this.isTransitioning = true;
        // Stop fetching timeout (which, when render is fast, means progress bar never shown)
        clearTimeout(this.isFetchingTimeout);
        // If progress bar has been shown, keep it for at least 500ms (to avoid flashing).
        setTimeout(function () { return _this.isFetching = false; }, 500);
    };
    AppComponent.prototype.onDocRemoved = function () {
        this.scrollService.removeStoredScrollInfo();
    };
    AppComponent.prototype.onDocInserted = function () {
        var _this = this;
        // Update the shell (host classes, sidenav state) to match the new document.
        // This may be called as a result of actions initiated by view updates.
        // In order to avoid errors (e.g. `ExpressionChangedAfterItHasBeenChecked`), updating the view
        // (e.g. sidenav, host classes) needs to happen asynchronously.
        setTimeout(function () { return _this.updateShell(); });
        // Scroll the good position depending on the context
        this.scrollService.scrollAfterRender(500);
    };
    AppComponent.prototype.onDocRendered = function () {
        var _this = this;
        if (this.isStarting) {
            // In order to ensure that the initial sidenav-content left margin
            // adjustment happens without animation, we need to ensure that
            // `isStarting` remains `true` until the margin change is triggered.
            // (Apparently, this happens with a slight delay.)
            setTimeout(function () { return _this.isStarting = false; }, 100);
        }
        this.isTransitioning = false;
    };
    AppComponent.prototype.onDocVersionChange = function (versionIndex) {
        var version = this.docVersions[versionIndex];
        if (version.url) {
            this.locationService.go(version.url);
        }
    };
    AppComponent.prototype.onResize = function (width) {
        this.isSideBySide = width >= this.sideBySideWidth;
        this.showFloatingToc.next(width > this.showFloatingTocWidth);
        if (this.isSideBySide && !this.isSideNavDoc) {
            // If this is a non-sidenav doc and the screen is wide enough so that we can display menu
            // items in the top-bar, ensure the sidenav is closed.
            // (This condition can only be met when the resize event changes the value of `isSideBySide`
            //  from `false` to `true` while on a non-sidenav doc.)
            this.sidenav.toggle(false);
        }
    };
    AppComponent.prototype.onClick = function (eventTarget, button, ctrlKey, metaKey, altKey) {
        // Hide the search results if we clicked outside both the "search box" and the "search results"
        if (!this.searchElements.some(function (element) { return element.nativeElement.contains(eventTarget); })) {
            this.hideSearchResults();
        }
        // Show developer source view if the footer is clicked while holding the meta and alt keys
        if (eventTarget.tagName === 'FOOTER' && metaKey && altKey) {
            this.dtOn = !this.dtOn;
            return false;
        }
        // Deal with anchor clicks; climb DOM tree until anchor found (or null)
        var target = eventTarget;
        while (target && !(target instanceof HTMLAnchorElement)) {
            target = target.parentElement;
        }
        if (target instanceof HTMLAnchorElement) {
            return this.locationService.handleAnchorClick(target, button, ctrlKey, metaKey);
        }
        // Allow the click to pass through
        return true;
    };
    AppComponent.prototype.setPageId = function (id) {
        // Special case the home page
        this.pageId = (id === 'index') ? 'home' : id.replace('/', '-');
    };
    AppComponent.prototype.setFolderId = function (id) {
        // Special case the home page
        this.folderId = (id === 'index') ? 'home' : id.split('/', 1)[0];
    };
    AppComponent.prototype.notificationDismissed = function () {
        var _this = this;
        this.notificationAnimating = true;
        // this should be kept in sync with the animation durations in:
        // - aio/src/styles/2-modules/_notification.scss
        // - aio/src/app/layout/notification/notification.component.ts
        setTimeout(function () { return _this.notificationAnimating = false; }, 250);
        this.updateHostClasses();
    };
    AppComponent.prototype.updateHostClasses = function () {
        var mode = "mode-" + this.deployment.mode;
        var sideNavOpen = "sidenav-" + (this.sidenav.opened ? 'open' : 'closed');
        var pageClass = "page-" + this.pageId;
        var folderClass = "folder-" + this.folderId;
        var viewClasses = Object.keys(this.currentNodes).map(function (view) { return "view-" + view; }).join(' ');
        var notificationClass = "aio-notification-" + this.notification.showNotification;
        var notificationAnimatingClass = this.notificationAnimating ? 'aio-notification-animating' : '';
        this.hostClasses = [
            mode,
            sideNavOpen,
            pageClass,
            folderClass,
            viewClasses,
            notificationClass,
            notificationAnimatingClass
        ].join(' ');
    };
    AppComponent.prototype.updateShell = function () {
        // Update the SideNav state (if necessary).
        this.updateSideNav();
        // Update the host classes.
        this.setPageId(this.currentDocument.id);
        this.setFolderId(this.currentDocument.id);
        this.updateHostClasses();
    };
    AppComponent.prototype.updateSideNav = function () {
        // Preserve current sidenav open state by default.
        var openSideNav = this.sidenav.opened;
        var isSideNavDoc = !!this.currentNodes[sideNavView];
        if (this.isSideNavDoc !== isSideNavDoc) {
            // View type changed. Is it now a sidenav view (e.g, guide or tutorial)?
            // Open if changed to a sidenav doc; close if changed to a marketing doc.
            openSideNav = this.isSideNavDoc = isSideNavDoc;
        }
        // May be open or closed when wide; always closed when narrow.
        this.sidenav.toggle(this.isSideBySide && openSideNav);
    };
    // Dynamically change height of table of contents container
    AppComponent.prototype.onScroll = function () {
        if (!this.tocMaxHeightOffset) {
            // Must wait until `mat-toolbar` is measurable.
            var el = this.hostElement.nativeElement;
            var headerEl = el.querySelector('.app-toolbar');
            var footerEl = el.querySelector('footer');
            if (headerEl && footerEl) {
                this.tocMaxHeightOffset =
                    headerEl.clientHeight +
                        footerEl.clientHeight +
                        24; //  fudge margin
            }
        }
        this.tocMaxHeight = (document.body.scrollHeight - window.pageYOffset - this.tocMaxHeightOffset).toFixed(2);
    };
    // Restrain scrolling inside an element, when the cursor is over it
    AppComponent.prototype.restrainScrolling = function (evt) {
        var elem = evt.currentTarget;
        var scrollTop = elem.scrollTop;
        if (evt.deltaY < 0) {
            // Trying to scroll up: Prevent scrolling if already at the top.
            if (scrollTop < 1) {
                evt.preventDefault();
            }
        }
        else {
            // Trying to scroll down: Prevent scrolling if already at the bottom.
            var maxScrollTop = elem.scrollHeight - elem.clientHeight;
            if (maxScrollTop - scrollTop < 1) {
                evt.preventDefault();
            }
        }
    };
    // Search related methods and handlers
    AppComponent.prototype.hideSearchResults = function () {
        this.showSearchResults = false;
        var oldSearch = this.locationService.search();
        if (oldSearch.search !== undefined) {
            this.locationService.setSearch('', tslib_1.__assign({}, oldSearch, { search: undefined }));
        }
    };
    AppComponent.prototype.focusSearchBox = function () {
        if (this.searchBox) {
            this.searchBox.focus();
        }
    };
    AppComponent.prototype.doSearch = function (query) {
        this.searchResults = this.searchService.search(query);
        this.showSearchResults = !!query;
    };
    AppComponent.prototype.onKeyUp = function (key, keyCode) {
        // forward slash "/"
        if (key === '/' || keyCode === 191) {
            this.focusSearchBox();
        }
        if (key === 'Escape' || keyCode === 27) {
            // escape key
            if (this.showSearchResults) {
                this.hideSearchResults();
                this.focusSearchBox();
            }
        }
    };
    return AppComponent;
}());
export { AppComponent };
